/** Komponenty */
import {
    AiOutlineCalendar as CalendarIcon,
    AiOutlineClockCircle as ClockIcon,
} from "react-icons/ai";
import { Article } from "../../../types/Article";
import HeaderSectionWithButton, {
    StyledIonButton,
} from "../../Layout/Header/HeaderSectionWithButton";
import styled from "@emotion/styled";
import { IconTextList } from "../../Layout/IconTextList/IconTextList";
import { IconTextItem } from "../../Layout/IconTextList/IconTextItem";
import { formatDate, formatMinutes } from "../../../helpers/formathelpers";
import { useIonRouter } from "@ionic/react";
import { PageContentWrapper } from "../../Layout/Page/PageContentWrapper";
import { PostExcerpt } from "../../Layout/Post/PostExcerpt";
import { PostImageWrapper } from "../../Layout/Post/PostImageWrapper";
import { PostText } from "../../Layout/Post/PostText";
import { PostTitle } from "../../Layout/Post/PostTitle";
import { useGetArticlesQuery } from "../../../services/article";
import Loader from "../../Controls/Loader/Loader";
import { devices } from "../../../theme/devices";
import { useCheckWindowWidth } from "../../../hooks/redux/useWindowWidth";
import LargeCardScroll from "../../Layout/Swipers/LargeCardScroll";
import { CardScroll } from "../../Layout/Swipers/CardScroll";
import { ReactSlick } from "../../Layout/Swipers/ReactSlick";

const StyledIonColSectionGray = styled("div")`
    background: ${({ theme }) => theme.colors.gray["160"]};
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 16px 120px;
`;

const StyledPostContentWrapper = styled(PageContentWrapper)`
    padding: 41px 16px;
    @media only screen and (min-width: ${devices.tablet}) {
        display: flex;
    }
`;
const StyledPageContentWrapper = styled(PageContentWrapper)<{
    cocktails: boolean;
}>`
    padding: 24px 16px;
    width: 100%;
    @media only screen and (min-width: ${devices.tablet}) {
        border-right: ${({ cocktails }) =>
            cocktails ? "1px solid rgba(34, 45, 56, 0.16)" : "none"};
        padding: 24px 16px;
    }
`;
const LargeSwiperWrapper = styled("div")`
    display: flex;
    flex-direction: column;
    height: 100vh;
    position: sticky;
    top: 0;
    padding: 16px 16px;
`;

const HeaderWrapper = styled("div")`
    display: flex;
    flex-direction: column;
    @media only screen and (min-width: ${devices.tablet}) {
        flex-direction: row;
        justify-content: space-between;
        gap: 20px;
    }
`;

interface ArticleContentProps {
    article: Article;
}

const ArticleContent = (props: ArticleContentProps) => {
    const { article } = props;
    const isTabletSize = useCheckWindowWidth("tablet");
    const router = useIonRouter();

    const {
        currentData: articlesData,
        isFetching: isFetchingArticles,
        isSuccess: loadingArticlesSucceeded,
    } = useGetArticlesQuery({
        is_featured: false,
    });

    return (
        <>
            <PostImageWrapper backgroundUrl={article.photo_path} />
            <StyledPostContentWrapper>
                <StyledPageContentWrapper
                    cocktails={article.cocktails.length > 0}
                >
                    <HeaderWrapper>
                        <PostTitle>{article.title}</PostTitle>
                        <IconTextList
                            style={{
                                gap: "16px",
                                whiteSpace: "nowrap",
                            }}
                        >
                            {article.time_read && (
                                <IconTextItem
                                    text={formatMinutes(article.time_read)}
                                    icon={<ClockIcon />}
                                />
                            )}
                            <IconTextItem
                                text={formatDate(article.created_at)}
                                icon={<CalendarIcon />}
                            />
                        </IconTextList>
                    </HeaderWrapper>
                    <PostExcerpt>{article.description_short}</PostExcerpt>
                    <PostText content={article.description} />
                </StyledPageContentWrapper>

                {!isTabletSize && article.cocktails.length > 0 && (
                    <LargeSwiperWrapper>
                        <HeaderSectionWithButton
                            header="Perfect serve"
                            padding={"5px 0 32px"}
                        />
                        <LargeCardScroll
                            direction="vertical"
                            items={article.cocktails.map((cocktail) => ({
                                id: cocktail.id,
                                title: cocktail.name,
                                isTransparent: cocktail.is_transparent,
                                subTitle: cocktail.taste,
                                photo: cocktail.photo_path,
                                onClick: () =>
                                    router.push(`/recipe/${cocktail.id}`),
                            }))}
                        />
                        <StyledIonButton
                            style={{ margin: "30px 0 0" }}
                            onClick={() => router.push("/recipes")}
                        >
                            Zobacz więcej
                        </StyledIonButton>
                    </LargeSwiperWrapper>
                )}
            </StyledPostContentWrapper>
            <StyledIonColSectionGray>
                <HeaderSectionWithButton
                    header="Podobne artykuły"
                    url="/knowledge-base"
                />

                {isFetchingArticles ? (
                    <Loader />
                ) : loadingArticlesSucceeded ? (
                    <CardScroll
                        cardVariant="square"
                        items={articlesData.data
                            .filter(
                                (relatedArticle) =>
                                    relatedArticle.id !== article.id
                            )
                            .map((relatedArticle) => ({
                                id: relatedArticle.id,
                                title: relatedArticle.title,
                                subTitle: relatedArticle.category?.name,
                                photo: relatedArticle.photo_path,
                                priority: relatedArticle.is_singled_out,
                                onClick: () =>
                                    router.push(
                                        `/article/${relatedArticle.id}`
                                    ),
                                iconTextItem: {
                                    text: relatedArticle.time_read
                                        ? formatMinutes(
                                              relatedArticle.time_read
                                          )
                                        : null,
                                    icon: <ClockIcon />,
                                },
                            }))}
                    />
                ) : null}

                {isTabletSize && article.cocktails.length > 0 && (
                    <>
                        <HeaderSectionWithButton
                            header="Perfect serve"
                            buttonContent="Więcej koktajli"
                            url="/recipes"
                        />
                        <ReactSlick
                            items={article.cocktails.map((cocktail) => ({
                                id: cocktail.id,
                                title: cocktail.name,
                                subTitle: cocktail.taste,
                                photo: cocktail.photo_path,
                                isTransparent: cocktail.is_transparent,
                                onClick: () =>
                                    router.push(`/recipe/${cocktail.id}`),
                            }))}
                        />
                    </>
                )}
            </StyledIonColSectionGray>
        </>
    );
};

export default ArticleContent;
