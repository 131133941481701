import { ApiRequestMultipleItemsParams } from "../types/Api/ApiRequestParams";
import {
    ApiResponseMultipleItems,
    ApiResponseSingleItem,
} from "../types/Api/ApiResponse";
import { Article } from "../types/Article";
import { api } from "./api";

export const articlesApi = api.injectEndpoints({
    endpoints: (build) => ({
        getArticle: build.query<ApiResponseSingleItem<Article>, number>({
            query: (id) => `/articles/${id}`,
        }),
        getArticles: build.query<
            ApiResponseMultipleItems<Article>,
            ApiRequestMultipleItemsParams<Article> & {
                category_ids?: number[];
                for_brand?: boolean;
                is_featured?: boolean;
            }
        >({
            query: (params) => ({ url: `/articles`, params }),
        }),
    }),
    overrideExisting: false,
});

export const {
    useGetArticleQuery,
    useGetArticlesQuery,
    useLazyGetArticleQuery,
    useLazyGetArticlesQuery,
} = articlesApi;
